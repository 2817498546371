@import "variable";

.Register {
  display: flex;
  height: 100%;
  .content_side {
    width: 100%;
    padding: 100px 1%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .content-title {
      color: #fff;
      font-size: 2em;
    }
    form {
      width: 40%;
    }
    .register-verification {
      width: 100% !important;
      .styles_react-code-input__CRulA {
        margin: 0 auto;
        display: flex;
        justify-content: center;
        flex-direction: row;
        input {
          width: 14.4444% !important;
          margin: 0 5px !important;
          height: 60px !important;
          background-color: #35B69D;
          border-radius: 10px !important;
          border: none !important;
          color: #fff !important;
        }
      }
    }
    //.extra-links {
    //  width: 65%;
    //  display: flex;
    //  justify-content: space-around;
    //  margin: 0 auto;
    //  button {
    //    display: flex;
    //    align-items: center;
    //    flex-direction: column;
    //    background-color: transparent;
    //    color: #C3F8E6;
    //    padding: 7px 30px 7px 10px;
    //    img {
    //      width: 34px;
    //      height: 34px;
    //      object-fit: contain;
    //    }
    //  }
    //  .selected {
    //    color: #C3F8E6;
    //  }
    //}
  }
  .laws_sign {
    text-align: center;
    color: #ffffff;
    h1 {
      margin-top: 10px;
      font-size: 20px;
    }
    p {
      font-size: 16px;
    }
    .laws_buttons_sign {
      display: flex;
      justify-content: space-around;
      flex-direction: row;
      button {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 3rem;
        font-weight: 600;
        &:nth-child(1) {
          color: #fff;
          &:hover {
            color: #6EC8AA !important;
          }
        }
      }
    }
  }
  .custom__modal-body {
    max-height: 80vh !important;
    overflow-y: scroll;
  }
  .modal_content {
    width: 100%;
    .modal__info-content,
    .modal__call-content {
      ul {
        list-style: none;
        li {
          color: $main_gray;
          b {
            margin-right: 5px;
            color: $main_dark;
          }
        }
      }
      p {
        color: $main_gray;
        b {
          color: $main_dark;
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .Register {
    margin-bottom: 20rem !important;
    flex-direction: column;
    .content_side {
      width: 100%;
      .content-title {
        font-size: 2em;
      }
      form {
        width: 90%;
      }

      }
  }
}
