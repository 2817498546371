@import "variable";
@import "sidebar";

.Cars {
  .cars__content {
    display: flex;
    margin: 0 !important;
    .cars__container {
      width: 100%;
      max-height: 570px;
      min-height: 570px;
      padding: 24px 16px 0 10px !important;
      .cars__content-main {
        position: relative;
        width: 100%;
        background-color: #fff;
        min-height: 520px;
        max-height: 520px;
        border-radius: 10px;
        padding: 10px 20px;
        overflow-y: auto;
      }
    }
  }
}

.car_info {
  width: 100%;
  display: flex;
  align-items: center;
  .img {
    width: 40px;
    height: 40px;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $main_color;
  }
  .title {
    color: $dark;
    font-size: 14px;
    margin-left: 12px;
  }
}
