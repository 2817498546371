.component__header-btn {
  outline: none !important;
  width: 30%;
  padding: 10px 40px;
  background: none;
  border: 2px solid #55c3ae;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s ease;
  color: $light_green;
  text-decoration: none !important;
  font-size: 15px;
  margin-top: 2px;
  box-shadow: none !important;
  outline: none;
  &:hover {
    background-color: #55c3ae;
    border: 2px solid #55c3ae;
    color: #fff;
  }
}

.new_btn {
  position: relative;
  .dash__link-badge {
    position: absolute;
    background-color: #ff1e46;
    top: -11px;
    right: 5px;
    border: 2px solid #fff;
    border-radius: 500rem;
    font-size: 11px;
    font-weight: 400;
    color: #fff;
  }
}

.custom-btn-light {
  outline: none !important;
  color: $secondary_color;
  background-color: $secondary_light;
  display: inline-block;
  text-align: center;
  padding: 9px 15px;
  border: 2px solid var(--bs-white);
  border-radius: 5px;
  text-decoration: none !important;
  transition: all 0.2s;

  &:hover {
    color: #fff;
    background-color: #05886d;
    transform: translateY(-1px);
  }

  &:active {
    color: #fff;
    background-color: $main_color;
    transform: translateY(0);
  }
}

.lang_btn {
  width: 28px;
  height: 28px;
  padding: 0;
  border-radius: 50%;
  box-shadow: none;
  -webkit-box-shadow: none;
  margin-left: 2rem;
  margin-right: 32px;
  .avatar {
    position: relative !important;
    transform: none !important;
    border: 1px solid #fff !important;
    border-radius: 50%;
    width: 100%;
    height: 100%;
  }
}

.close-btn {
  outline: none !important;
  position: absolute;
  right: 15px;
  border: none;
  outline: none;
  background: none;
  font-size: 1.5em;
  transition: all 0.2s linear;
  &:hover {
    transform: rotate(90deg);
  }
}

@media (max-width: 1200px) {
  .component__header-btn {
    width: 100%;
  }
}
