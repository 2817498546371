@import "variable";

.Pay {
  .protocol-pay__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 60px 40px;
    width: 100%;
    .warn {
      width: 50%;
      margin-top: 1rem;
    }
    form {
      width: 50%;
      margin: 0 auto;
      .protocol-pay__serie-container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        .protocol__serie-item {
          width: 100%;
          border-radius: 10px;
          background-color: #fff;
          border: 1px solid #e8e8e8;
          padding: 30px 30px 20px 20px;
          display: flex;
          margin: 10px 5px 1rem 5px;
          flex-direction: column;
          align-items: center;
          cursor: pointer;
          transition: all 0.2s linear;
          &:hover {
            box-shadow: 0px 0px 0.2px 0.2px #00000012;
          }
          p {
            font-weight: 800;
            color: #565656;
          }
          .protocol__serie-item-title {
            font-size: 0.7em;
            text-align: center;
          }
          img {
            width: 100%;
            height: 60px;
            object-fit: contain;
            margin-bottom: 10px;
          }
        }
        .b_img {
          width: 100%;
          padding: 0 !important;
          padding: 15px 10px 10px 10px !important;
          img {
            width: 200px;
            height: 87px;
          }
        }
      }
      .protocol-pay__serie-container.bb {
        padding: 10px 0px;
        border-bottom: 1px solid $main_solid;
        .protocol__serie-item {
          width: 70%;
          height: 140px;
          box-shadow: none;
          img {
            height: 100%;
          }
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .Pay {
    .protocol-pay__content {
      padding: 50px 10px;
      .warn {
        width: 100%;
        margin-top: 1rem;
      }
      form {
        width: 100%;
        .protocol-pay__serie-container {
          display: flex;
          flex-direction: column;
          .protocol__serie-item {
            width: 80%;
            margin-bottom: 20px;
            .protocol__serie-item-title {
              font-size: 0.6em;
            }
          }
        }
        .protocol-pay__serie-container.bb {
          padding: 0px;
          .protocol__serie-item {
            width: 50% !important;
            margin: 10px 0 50px 0;
            img {
              height: 60px;
              margin-bottom: 0px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 543px) {
  .protocol-pay__serie-container.bb {
    padding: 0px;
    .protocol__serie-item {
      width: 100% !important;
      margin: -10px 0 10px 0 !important;
      img {
        height: 60px;
        margin-bottom: 0px;
      }
    }
  }
}
