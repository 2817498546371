.navimax {
  height: 100%;
  .card {
    margin-top: 1.5rem;
    .card-content {
      min-height: 550px;
      height: 550px;
      display: flex;
      background-color: #f4f4f4;
      .left {
        width: 50%;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .right {
        width: 50%;
        margin: 0 auto;
        iframe {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .navimax {
    height: 100%;
    .card {
      margin-top: 0rem;
      .card-content {
        .left {
          display: none;
        }
        .right {
          width: 100%;
          iframe {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}
