.profile {
  height: 100%;
  .card-content {
    min-height: 600px;
    height: 600px;
    display: flex;
    background-color: #f4f4f4;
    .sidebar {
      width: 30%;
      height: 100%;
      background-color: #fff;
      .user {
        display: flex;
        align-items: center;
        padding: 1rem;
        border-bottom: 1px solid #f4f4f4;
        svg {
          width: 48px;
          height: 48px;
        }
        .user-detail {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          margin-left: 8px;
          .operator {
            font-size: 14px;
            color: #7b7b7b;
            font-weight: 400;
          }
          .number {
            font-size: 1rem;
            font-weight: 500;
            color: $dark;
          }
        }
      }
      .tabs {
        padding: 1rem;
        .tab_item {
          width: 100%;
          background-color: transparent;
          outline: none;
          border: none;
          transition: all 0.3s;
          margin-bottom: 0.5rem;
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 1rem;
          border-radius: 12px;
          font-size: 14px;
          cursor: pointer;
          text-decoration: none;
          .title {
            color: $dark;
            font-weight: 500;
          }
          svg {
            margin-right: 12px;
          }
          svg path {
            fill: $secondary_color;
          }
          &.active {
            background-color: $secondary_light;
            color: $secondary_color;
          }
        }
      }
    }
    .d_content {
      width: 70%;
      min-height: 100%;
      padding: 1rem;
      overflow-y: auto;
      .details {
        width: 100%;
        height: 100%;
        background-color: $white;
        border-radius: 12px;
        position: relative;
        .loading {
          border-radius: 12px;
        }
        .Balance {
          .balance__content {
            padding: 20px;
          }
        }
        .pay_form {
          padding: 0.5rem 2rem;
        }
        .Point {
          width: 80%;
          .point__content {
            padding: 20px;
            form {
              width: 100%;
            }
          }
        }
        .Number {
          width: 80%;
          margin: 0 auto;
          .number__content {
            padding: 20px;
            form {
              width: 100%;
            }
          }
        }
        .Password {
          width: 80%;
          margin: 0 auto;
          .password__content {
            padding: 20px;
            form {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .profile {
    height: 100%;
    .card-content {
      .sidebar {
        position: absolute;
        left: -14rem;
        z-index: 5;
        transition: all 0.3s;
        &.active {
          width: 100%;
          left: 0;
        }
      }
      .d_content {
        width: 100%;
        overflow-y: auto;
        .details {
          width: 100%;
          //overflow-x: auto;
          .Point {
            width: 100%;
            .point__content {
              padding: 20px;
              form {
                width: 100%;
              }
            }
          }
          .Number {
            width: 100%;
            margin: 0 auto;
            .number__content {
              padding: 20px;
              form {
                width: 100%;
              }
            }
          }
          .Password {
            width: 100%;
            margin: 0 auto;
            .password__content {
              padding: 20px;
              form {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}
