@import "variable";

.Insurance {
  .insurance__container {
    padding: 30px;
    height: 100%;
    min-height: 550px;
    margin: 0 auto;
    background-color: #fff;
    .insurance__partner-container {
      width: 60%;
      display: flex;
      justify-content: space-around;
      margin: auto;
      .partner__item {
        display: flex;
        justify-content: space-around;
        width: 100%;
        img {
          width: 95%;
          object-fit: contain;
        }
      }
    }
    .insurance__partner-about {
      width: 90%;
      padding: 10px 5px;
      margin: auto;
      border-radius: 8px;
      p {
        text-align: center;
        margin-bottom: 10px;
        color: $main_gray;
        b {
          color: $main_dark;
        }
      }
    }
    .insurance__partner-call {
      width: 70%;
      margin: auto;
      display: flex;
      justify-content: center;
      flex-direction: row;
      align-items: center;
      background-color: #fff;
      border-radius: 8px;
      border: 1px solid #E8E8E8;
      .partner__call-image {
        img {
          width: 20px;
          object-fit: contain;
        }
      }
      a {
        margin: 0 10px;
        color: $main_blue;
      }
    }
  }
}

@media (max-width: 1024px) {
  .Insurance {
    .insurance__container {
      padding: 20px 10px;
      p {
        font-size: 0.7em;
        text-align: center;
      }
      table {
        font-size: 0.7em;
      }
      .insurance__partner-container {
        width: 100%;
        .partner__item {
          display: flex;
          justify-content: space-around;
          width: 100%;

          img {
            width: 80%;
            object-fit: contain;
          }
        }
      }
      .insurance__partner-about {
        width: 97%;
        p {
          font-size: 0.8em;
        }
      }
      .insurance__partner-call {
        border: none;
        display: flex;
        flex-direction: column;
        img {
          margin-bottom: 10px;
        }
        a {
          margin-bottom: 3px;
          font-size: 0.9em;
        }
      }
    }
  }
}
