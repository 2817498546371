@import "variable";
@import "card";


.app__main {
  height: auto;
  padding-bottom: 60px;
  .content {
    margin-top: 1.5em;
    min-height: 600px;
    //position: relative;
    border-radius: 10px;
    overflow: hidden;
    padding: 0 !important;
    background-color: #F4F4F4;
    box-shadow: 0px 0px 20px #00000012;
  }

  .content_min {
    min-height: 550px;
    border-radius: 10px;
    overflow: hidden;
    margin-top: 3em !important;
    background-color: $main_color;
    margin: 0 auto;
    .header_content {
      display: none;
    }
  }

  .content_middle {
    width: 600px;
    min-height: 600px;
    border-radius: 6px;
    overflow: hidden;
    margin-top: 2.5em !important;
    background-color: #F4F4F4;
    margin: 0 auto;
  }
}

@media (max-width: 1024px) {
  .app__main {
    padding-bottom: 60px;
    margin: 0 !important;
    min-height: auto;
    border-radius: 0 !important;
    background-color: #F4F4F4;
    background-image: none !important;
    .content {
      border-radius: 0px;
      min-height: 90vh !important;
      box-shadow: none;
      margin: 0;
    }
    .content_min {
      width: 100%;
      min-height: 800px !important;
      max-height: 800px !important;
      border-radius: 0 !important;
      margin-top: 0 !important;
      background: linear-gradient(to right, $secondary_color, $main_color);
      .header_content {
        display: block;
      }
    }
    .content_middle {
      width: 100%;
      min-height: 90vh;
      border-radius: 0 !important;
      margin: 0 auto !important;
      //margin-top: 50px !important;
    }
  }
}
