@import "variable";

.Login {
  display: flex;
  height: 100%;
  .content_side {
    width: 100%;
    padding: 60px 1%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .content-title {
      color: #fff;
      font-size: 2em;
    }
    form {
      width: 90%;
    }
    .extra-links {
      width: 65%;
      display: flex;
      justify-content: space-around;
      button {
        display: flex;
        align-items: center;
        flex-direction: column;
        background-color: transparent;
        color: #C3F8E6;
        padding: 7px 30px 7px 10px;
        img {
          width: 34px;
          height: 34px;
          object-fit: contain;
        }
      }
      .selected {
        color: #C3F8E6;
      }
    }
  }
  .custom__modal-body {
    max-height: 80vh !important;
    overflow-y: scroll;
  }
  .modal_content {
    width: 100%;
    .modal__info-content,
    .modal__call-content {
      ul {
        list-style: none;
        li {
          color: $main_gray;
          b {
            margin-right: 5px;
            color: $main_dark;
          }
        }
      }
      p {
        color: $main_gray;
        b {
          color: $main_dark;
        }
      }
    }
  }
}

.content-h {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 42%;
  margin-bottom: 2rem;
}

@media (max-width: 1024px) {
  .Login {
    flex-direction: column-reverse;
    .content_side {
      width: 100%;
      .content-title {
        font-size: 2em;
      }
      form {
        width: 90%;
      }
    }
  }
  .content-h {
    width: 92%;

  }
}
