.card {
  margin-top: 1.5rem;
  border: none;
  position: relative;
  overflow: hidden;
  .card-header {
    padding: 0;
    border: none;
  }
  .card-content {
    min-height: 500px;
    position: relative;
    //display: flex;
    //justify-content: center;
    //align-items: center;
  }
}

@media (max-width: 1200px) {
  .card {
    margin-top: 0;
    border-radius: 0;
  }
}

@media (max-width: 350px) {
  .card {
    .card-content {
      .no_card {
        padding: 20px;
        width: 100%;
      }
    }
  }
}