.alert {
  border: 0;
  position: relative;
  padding: .75rem 1.25rem;
  margin-bottom: 1rem;
  border-radius: .25rem;

  &:empty {
    display: none;
  }

  &[data-type=success] {
    color: #2dce89;
    background-color: rgba(45, 206, 137, 0.2);
  }
  &[data-type=error] {
    color: #f5365c;
    background-color: rgba(245, 54, 92, 0.1);
  }
}