
// list
.cards_list {
  width: 100%;
  padding: 20px;
  .feather-trash-2 {
    margin: 2.3rem .5rem 0 0;
    height: 2rem;
    font-size: 1.5rem;
    color: #f5365c;
    transition: all .3s;
    &:hover {
      animation: action 1s forwards;
      cursor: pointer;
    }
  }
  .cards_item {
    width: 100%;
    min-height: 6rem;
    margin-bottom: 20px;
    background: #F6F6F6;
    position: relative;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    border: 2px solid transparent;
    transition: all .2s ease-in-out;
    cursor: pointer;
    padding: 0 20px;
    .checkbox_ {
      position: absolute;
      right: 1.5rem;
      top: 2.4rem;
    }
    .spinner-border {
      width: 1em;
      height: 1em;
      border-width: .1em;
      margin: 1.1rem 0 0 .5rem;
    }
    .card-top {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      span {
        padding: 2px 10px;
        background-color: #6EC8AA;
        margin-right: 5px;
        border-radius: 500rem;
        color: #fff;
      }
      p {
        color: $secondary_color;
        font-size: 19px;
        margin: 0 0 0 .5rem;
      }
    }
    .card-bottom {
      display: flex;
      p {
        margin: 1rem 0 0 .3rem;
      }
      .text-muted {
        font-size: 14px;
        margin-top: 18px;
      }
    }
    &:hover {
      background: none;
      border: 2px solid $secondary_color;
    }
  }
}

@media (max-width: 1200px) {
    // list
    .cards_list {
      padding: 20px;
        .feather-trash-2 {
          margin: 1.7rem .5rem 0 0;
          height: 2rem;
          font-size: 1.3rem;
          color: #f5365c;
          transition: all .3s;
          &:hover {
            animation: action 1s forwards;
            cursor: pointer;
          }
        }
      .activeFeather {
        margin: 2.5rem .5rem 0 0 !important;
      }
      .cards_item {
        width: 100%;
        min-height: 4rem;
        margin-bottom: 20px;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding: 10px 20px;
        .spinner-border {
          width: 1em;
          height: 1em;
          border-width: .1em;
          margin: 3px 0 0 .5rem;
        }
        .checkbox_ {
          position: absolute;
          right: 1rem;
          top: 1.8rem;
        }
        .activeCheck {
          top: 2.4rem;
        }
        .card-top {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          span {
            width: 23px;
            height: 23px;
            background-color: #6EC8AA;
            margin-right: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 13px;
            border-radius: 500rem;
            color: #fff;
          }
          p {
            color: $secondary_color;
            font-size: 18px;
            margin: 0 0 0 .5rem;
          }
        }
        .card-bottom {
          flex-direction: column;
          p {
            font-size: 14px;
            margin: .5rem 0 0 .3rem;
          }
          .text-muted {
            margin-top: 0;
          }
        }
      }
    }
}

@keyframes action {
  0% {transform: rotate(0deg)}
  20% {transform: rotate(10deg)}
  40% {transform: rotate(-10deg)}
  60% {transform: rotate(0deg)}
}