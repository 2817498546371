._center {
  align-items: center;
  justify-content: center;
}

._ai-center {
  align-items: center;
}

._ai-baseline {
  align-items: baseline;
}

._ai-start {
  align-items: flex-start;
}

._ai-end {
  align-items: flex-end;
}

._jc-center {
  justify-content: center;
}

._jc-between {
  justify-content: space-between;
}

._jc-start {
  justify-content: flex-start;
}

._jc-end {
  justify-content: flex-end;
}