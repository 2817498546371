.popup {
  .modal-dialog {
    .modal-content {
      border-radius: 8px;
      border: none;
      .modal-header {
        color: $dark;
        background-color: $secondary_light;
        padding: 1rem;
        border-radius: 8px 8px 0 0;
        border: none;
        h5 {
          font-size: 16px;
          font-weight: 500;
        }
        .close-btn {
          &::before {
            color: $dark;
          }
        }
      }
      .modal-body {
        padding: 20px 1rem;
      }
    }
  }
}
