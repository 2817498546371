@import "variable";
@import "sidebar";

@import "variable";

.Point {
  width: 70%;
  margin: 0 auto;
  .custom-row-header {
    margin: 0px;
    padding-top: 10px;
    color: $main_blue;
  }
  .point__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 50px 10px;
    form {
      width: 60%;
    }
  }
}

@media (max-width: 1024px) {
  .Point {
    width: 100%;
    .point__content {
      padding: 50px 20px;
      form {
        width: 100%;
      }
    }
  }
}
