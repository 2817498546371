@import "variable";

.Question {
  .question__container {
    padding: 50px;
    .question__item {
      // cursor: pointer;
      display: block;
      text-decoration: none;
      color: black;
      // opacity: 0.6;
      padding: 20px 30px;
      margin-bottom: 20px;
      border-radius: 50px;
      transition: all 0.2s linear;
      background-color: #fff;
      &:hover {
        opacity: 1;
      }
      &:hover {
        .question__item-header {
          .question__item-actions {
            .item__delete {
              opacity: 1;
            }
          }
        }
      }
      .question__item-header {
        position: relative;
        display: flex;
        padding: 0;
        //border-bottom: 1px dashed $main_gray;
        .question__item-image {
          margin-right: 10px;
          img {
            width: 20px;
            height: 15px;
            object-fit: contain;
            vertical-align: middle;
          }
        }
        .question__item-info {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          .item__sender {
            font-size: 1em;
            color: $main_color;
            font-weight: 500;
            margin: 0px;
          }
          .item__date {
            font-size: 1em;
            color: $main_color;
            font-weight: 500;
            margin: 0px;
          }
        }
        .question__item-actions {
          position: absolute;
          right: 0px;
          top: 200%;
          z-index: 99 !important;
          transform: translateY(-50%);
          .item__delete {
            outline: none;
            border: none;
            background: none;
            font-size: 1.5em;
            color: $danger_color;
            opacity: 0;
            transition: all 0.2s linear;
          }
        }
      }
      .question__item-content {
        div {
          position: relative;
          p {
            margin: 0px;
            font-size: 0.9em;
            b {
              margin-right: 10px;
              text-transform: uppercase;
            }
          }

        }
        .question__item-question {
          padding: 10px 0;
          b {
            color: #aeaeae;
          }
        }
        .question__item-reply {
          position: relative;
          padding: 5px 15px;
          margin-left: 45px;
          background-color: $color_green;
          border-radius: 10px;
          color: $main_color !important;
          form {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            textarea {
              margin-bottom: 20px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .Question {
    .question__container {
      padding: 10px;
      .question__item {
        padding: 20px 20px;
        border-radius: 20px;
        .question__item-header {
          margin-bottom: 5px;
          .question__item-info {
            .item__sender {
              font-size: .9em;
            }
            .item__date {
              font-size: .9em;
            }
          }
          .question__item-actions {
            .item__delete {
              font-size: 1em;
              opacity: 1;
            }
          }
        }
        .question__item-content {
          div {
            p {
              font-size: 0.8em;
              b {
              }
            }
            &::before {
              display: none;
            }
          }
        }
      }
    }
  }
}

.QuestionAdd {
  .question__add-container {
    padding: 50px;
    display: flex;
    justify-content: center;
    form {
      width: 80%;
      button {
        width: 50%;
      }
    }
  }
}

@media (max-width: 1024px) {
  .QuestionAdd {
    .question__add-container {
      padding: 50px 10px;
      form {
        width: 90%;
        button {
          width: 100%;
        }
      }
    }
  }
}
