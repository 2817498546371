@import "variable";
@import "sidebar";

.License {
  .license__content {
    display: flex;
    .license__container {
      width: 100%;
      max-height: 570px;
      min-height: 570px;
      padding: 24px 16px 0 10px !important;
      .license__content-main {
        position: relative;
        width: 100%;
        background-color: #fff;
        min-height: 520px;
        max-height: 520px;
        border-radius: 10px;
        padding: 10px 20px;
        overflow-y: auto;
        .custom-row-header {
          margin: 0px;
          padding-top: 10px;
          color: $main_blue;
        }
    }

    }
  }
}


@media (max-width: 1024px) {
  .License {
    .license__content {
      .license__container {
        .license__content-main {
          padding: 50px;
        }
      }
    }
  }

}












