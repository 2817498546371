@import "variable";

.Sidebar {
  position: relative;
  width: 35%;
  margin: 25px 0 22px 20px;
  padding: 0 !important;
  background-color: #f4f4f4;
  display: flex;
  flex-direction: column;
  .sidebar_title {
    p {
      color: #16a085;
      font-weight: 600;
      margin: 0.5rem 0 0.5rem 0.3rem;
    }
  }
  .sidebar__item-header {
    padding: 0px 20px;
    height: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .sidebar__item-container {
    position: relative;
    overflow-y: scroll;
    overflow-x: hidden;
    height: 410px !important;
    &.history {
      height: 460px !important;
    }
    .sidebar__item {
      background-color: #fff;
      margin-bottom: 10px;
      border-radius: 10px;
      cursor: pointer;
      height: 50px;
      padding: 15px 0px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
      transition: all 0.2s linear;
      .sidebar__item-content {
        line-height: 17px;
        p {
          color: #4a4949 !important;
          font-weight: 500;
        }
      }
      &:hover {
        background-color: $main_default;
      }
      .sidebar__item-image {
        padding: 0px 10px;
        width: 20%;
        img {
          width: 100%;
          object-fit: contain;
        }
        .sidebar__item-count {
          margin: 0px;
          color: $main_blue;
        }
      }
      .sidebar__item-content {
        padding: 0px 10px;
        width: 100%;
        .sidebar__item-title {
          p {
            margin: 0 !important;
            color: $main_blue;
          }
        }
        .sidebar__item-date {
          span {
            font-size: 0.7em;
          }
        }
      }
      .sidebar__item-status {
        display: flex;
        p {
          margin: 0 8px 0 0 !important;
          font-size: 13px;
        }
        img {
          margin-right: 0.6rem;
        }
        //i {
        //  font-size: 1.2em;
        //}
        .active {
          color: $main_color;
        }
        .no_balance {
          color: #eda73d;
          white-space: nowrap;
        }
        .deactive {
          color: $main_gray;
        }
        .delete {
          color: $danger_color;
          transition: all 0.2s linear;
          margin-right: 8px;
          &:hover {
            transform: scale(1.3);
          }
        }
      }
    }
    .sidebar__item.active {
      background-color: #c3f8e6;
      .sidebar__item-count,
      .sidebar__item-title p,
      .sidebar__item-date span {
        color: #464646;
      }
    }
  }
  .export {
    margin-bottom: 5px;
    //display: flex !important;
    //flex-direction: column !important;
  }
  .sidebar__item-footer {
    padding: 0;
    height: 30%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
  }
}

@media (max-width: 1024px) {
  .Sidebar {
    width: 100%;
    margin: 10px 10px;
    .sidebar_title {
      display: none;
    }
    .sidebar__item-container {
      height: 440px !important;
      .sidebar__item {
        height: auto;
      }
      .sidebar__item-collapse {
        position: relative;
        border-radius: 10px;
        margin-bottom: 12px;
        padding: 15px 20px;
        min-height: 120px;
        background-color: $main_default;
        .custom-row__data {
          width: 100%;
          display: flex;
          .data-title,
          .data-content {
            min-width: 50%;
            margin: 5px 0px;
            padding: 0px 5px;
            font-size: 0.8em;
          }
          .data-title {
            color: $main_blue;
          }
          .data-content {
            display: flex;
            color: $main_dark;
            .link {
              font-size: 1em;
              text-decoration: none;
              padding: 0px 5px;
              border: none;
            }
          }
        }
        .protocol__empty-content {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          img {
            width: 20%;
            object-fit: contain;
            margin-bottom: 20px;
          }
          .protocol__empty-content-text {
            color: $main_dark;
            font-size: 0.9em;
            text-align: center;
          }
        }
      }
    }
    .export {
      position: relative !important;
    }
    .sidebar__item-footer {
      padding: 4px;
      height: auto;
      margin-left: -2px;
    }
    .sidebar__item-header {
      padding: 20px;
      height: auto;
    }
  }
}
