@import "variable";

.Message {
  padding: 50px;
  .message__container {
    .message__item {
      // cursor: pointer;
      display: block;
      text-decoration: none;
      color: black;
      opacity: 1;
      padding: 20px 20px 10px 20px;
      margin-bottom: 20px;
      border-radius: 10px;
      align-items: center;
      transition: all 0.2s linear;
      background-color: #fff;
      &:hover {
        .message__item-footer {
          .message__item-actions {
            .message__delete {
              opacity: 1;
            }
          }
        }
      }
      .message__item-footer {
        position: relative;
        display: flex;
        justify-content: space-between;
        padding: 10px 0px;
        .mes {
          font-size: 15px;
        }
        .message__item-image {
          margin-right: 10px;
          img {
            width: 20px;
            height: 15px;
            object-fit: contain;
            vertical-align: middle;
          }
        }
        .message__item-name {
          p {
            font-size: 1.1em;
            font-weight: 700;
            margin: 0px;
          }
        }
        .message__item-actions {
          // position: absolute;
          // right: 0px;
          // top: 0px;
          .message__delete {
            outline: none;
            border: none;
            background: none;
            font-size: 1.5em;
            color: $danger_color;
            opacity: 0;
            transition: all 0.2s linear;
          }
        }
      }
      .message__item-content {
        .message_rows {
          display: flex;
          justify-content: space-between;
        }
        p {
          margin: 0px;
          color: $main_color;
          font-size: 1em;
        }
        span {
          padding: 2px 10px;
          background-color: #6EC8AA;
          margin-right: 5px;
          border-radius: 500rem;
          color: #fff;
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .Message {
    padding: 10px;
    .message__container {
      .message__item {
        .message__item-content {
          .message_rows {
            p {
              font-size: .8rem;
            }
          }
        }
        .message__item-footer {
          .mes {
            font-size: .8em;
          }
          .message__item-actions {
            .message__delete {
              opacity: 1;
              font-size: 1em;
            }
          }
        }
      }
    }
  }
}
