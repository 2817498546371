@import "variable";

footer {
  background-color: $main_default;
  .row {
    margin: 0px;
  }
  .footer__top-section {
    padding: 15px 0px;
    border-bottom: 1px solid $main_solid;
    .footer__card-icons {
      display: flex;
      justify-content: space-between;
      align-items: center;
      a {
        display: flex;
        justify-content: center;
        margin: 0px 15px;
        img {
          width: 100%;
          height: 30px;
          object-fit: contain;
        }
      }
      .bakcell {
        img {
          height: 20px;
        }
      }
    }
    .footer__store-icons {
      display: flex;
      justify-content: end;
      align-items: center;
      a {
        display: flex;
        justify-content: center;
        margin: 0px 5px;
        img {
          width: 100px;
          object-fit: contain;
        }
        .app_glory {
          width: 103px;
          margin-right: 2px !important;
        }
      }
    }
  }
  .footer__bottom-section {
    padding: 10px 0px;
    p,
    a {
      text-decoration: none;
      margin: 0px;
      font-size: 10px;
      color: $main_gray;
      i {
        margin-right: 5px;
      }
    }
  }
}


@media (max-width: 767px) {
  .footer__store-icons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    a {
      display: flex;
      justify-content: center;
      margin: 0px 5px;
      img {
        width: 100px !important;
        object-fit: contain;
      }
    }
  }
}


@media (max-width: 336px) {
  .footer__store-icons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    a {
      display: flex;
      justify-content: center;
      margin: 0px 5px;
      img {
        width: 90px !important;
        object-fit: contain;
      }
    }
  }
}