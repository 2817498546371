@import "variable";

.warn {
  width: 100%;
  padding: 20px 20px 10px 20px;
  border-radius: 15px;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .icon {
    margin-top: -1em !important;
  }
  p {
    font-size: 14px;
    color: #717171;
    margin-left: 1em;
  }
}


