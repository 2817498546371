@import "variable";


.Forgot {
  display: flex;
  min-height: 500px;
  .content_side {
    width: 100%;
    padding: 1% 3% !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .content-title {
      color: #fff;
      font-size: 2em;
    }
    form {
      width: 90%;
    }
  }
}

@media (max-width: 1024px) {
  .Forgot {
    .content_side {
      width: 100%;
      .content-title {
        font-size: 2em;
      }
      form {
        width: 90%;
      }
    }
  }
}
