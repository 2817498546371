@import "variable";

header {
  position: relative;
  padding: 30px 0px;
  .content {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    .header__actions {
      margin-top: -8px;
      .header__auth {
        display: flex;
        align-items: center;
        .contact_number {
          span {
            color: #fff;
            font-size: 20px;
            font-weight: 700;
            text-decoration: none !important;
          }
          img {
            margin-top: -6px;
          }
        }
        .signin_btn {
          white-space: nowrap;
          //width: 170px;
          height: 2.3rem;
          padding-left: 1rem;
          padding-right: 1rem;
          background-color: transparent;
          border: 2px solid #fff;
          border-radius: 6px;
          transition: 0.3s ease;
          color: #fff;
          text-decoration: none;
          font-size: 15px;
          outline: none;
          &:hover {
            background-color: #fff;
            color: #2dce89;
          }
        }
        .signup_btn {
          width: 170px;
          height: 2.3rem;
          background-color: transparent;
          border-radius: 6px;
          border: 2px solid $light_green;
          font-size: 15px;
          transition: 0.3s ease;
          color: #2dce89;
          outline: none;
          text-decoration: none;
          &:hover {
            background-color: transparent;
            color: #fff;
          }
        }
      }
      .header__auth-info {
        display: flex;
        align-items: center;
        .header__menu-toogler {
          text-decoration: none;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin: 0px 24px 0 0px;
          transition: all 0.2s linear;
          img {
            width: 2em;
          }
          &:hover {
            opacity: 0.5;
          }
        }
        .lang_btn {
          margin: 0 24px;
        }
        .contact_number {
          display: flex;
          align-items: center;
          span {
            color: #fff;
            font-size: 20px;
            font-weight: 700;
            user-select: none !important;
          }
          svg {
            margin-right: 8px;
          }
        }
        .header__auth-operator {
          display: flex;
          align-items: center;
          cursor: pointer;
          margin: 0 0 0 24px;
          text-decoration: none;
          svg {
            margin-right: 8px;
          }
          .caption {
            font-size: 14px;
          }
          .auth-operator-toogler {
            position: relative;
            border: none;
            background: none;
            outline: none;
            display: flex;
            align-items: start;
            justify-content: center;
            flex-direction: column;
            i,
            span {
              transition: all 0.2s linear;
            }
            i {
              font-weight: 800;
              position: absolute;
              top: 5px;
              right: -20px;
              color: $main_default;
            }
            span {
              display: block;
              color: #fff;
            }
            .operator-number {
              display: flex;
              align-items: center;
              font-size: 1rem;
              svg {
                margin-left: 4px;
              }
            }
            .operator-name {
              font-size: 0.7em;
              text-align: left;
            }
          }
        }
        .header__auth-messages {
          .message_toogler {
            position: relative;
            outline: none;
            border: none;
            background: none;
            color: #fff;
            text-decoration: none;
            transition: all 0.2s linear;
            img {
              opacity: 0.8;
              width: 1.3em;
            }
            &:hover {
              opacity: 0.5;
            }
            .message-count {
              position: absolute;
              top: -9px;
              left: 14px;
              display: flex;
              justify-content: center;
              align-items: center;
              min-width: 23px;
              height: 23px;
              border-radius: 20px;
              background-color: $danger_color;
              border: 1px solid #fff;
              color: #c3f8e6;
              font-size: 0.5em;
              line-height: 20px;
              text-align: center;
            }
          }
        }
        .log-out-side {
          margin-top: 9px;
          margin-left: 30px;
          i {
            color: #fff;
            opacity: 0.8;
            font-size: 2rem;
            transition: all 0.2s linear;
            &:hover {
              opacity: 0.5;
              cursor: pointer;
            }
          }
        }
      }
      .header__auth-buttons {
        button {
          padding: 5px 30px;
        }
      }
    }
  }
  .custom__dropdown {
    position: absolute;
    top: 110%;
    right: 13%;
    z-index: 10;
    width: 370px;
    height: 520px;
    background-color: $main_default;
    border-radius: 18px;
    //  &:after {
    //  content: "";
    //  position: absolute;
    //  width: 20px;
    //  height: 20px;
    //  top: -19px;
    //  right: 10%;
    //  clip-path: polygon(50% 40%, 0% 100%, 100% 100%);
    //  background-color: $main_color;
    //}
    .custom__dropdown-header {
      background-color: $main_color;
      height: 10%;
      align-items: center;
      border-radius: 18px;
      padding: 0px 25px;
      display: flex;
      justify-content: center;
      p {
        margin-top: 14px;
        color: $light_green;
      }
      img {
        width: 1.7em;
        margin-right: 12px;
      }
    }
    .custom__dropdown-content {
      position: relative;
      height: 80%;
      overflow-y: scroll;
      .custom__dropdown-item-container {
        position: relative;
        min-height: 100%;
        flex-direction: column;
        justify-content: center;
        .message__item {
          text-decoration: none;
          color: black;
          opacity: 1;
          padding: 10px 20px;
          transition: all 0.2s linear;
          &:hover {
            opacity: 1;
          }
          &:hover {
            .message__item-footer {
              .message__item-actions {
                .message__delete {
                  opacity: 1;
                }
              }
            }
          }
          .message__item-footer {
            display: flex;
            justify-content: flex-end;
            padding: 5px 0px;
            position: relative;
            .message__item-image {
              margin-right: 10px;
              img {
                width: 20px;
                height: 15px;
                object-fit: contain;
                vertical-align: middle;
              }
            }
            .message__item-name {
              p {
                font-size: 1.1em;
                font-weight: 700;
                margin: 0px;
              }
            }
            .message__item-actions {
              // position: absolute;
              // right: 0px;
              // top: 0px;
              .message__delete {
                outline: none;
                border: none;
                background: none;
                font-size: 1.2em;
                color: $danger_color;
                opacity: 0;
                transition: all 0.2s linear;
              }
            }
          }
          .message__item-content {
            width: 100% !important;
            margin: 0 auto;
            border-bottom: 1px solid #aeaeae;
            .left {
              width: 50%;
            }
            .right {
              width: 50%;
            }
            p {
              margin-bottom: 5px;
              color: $main_gray;
              font-size: 0.9em;
            }
            .mes {
              color: #434141 !important;
            }
            span {
              padding: 2px 7px;
              background-color: #6ec8aa;
              margin-right: 5px;
              border-radius: 500rem;
              color: #fff;
            }
          }
          .message__item-date {
            p {
              margin: 0px;
              font-size: 0.7em;
            }
          }
        }
        .dropdown__empty-content {
          color: $main_gray;
          font-size: 1.5em;
          text-align: center;
        }
      }
    }
    .custom__dropdown-footer {
      background-color: $main_color;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.2s linear;
      height: 10%;
      padding: 0px 25px;
      border-radius: 18px;
      a {
        text-decoration: none;
        color: $light_green;
        text-align: center;
        font-size: 1em;
      }
      &:hover {
        background-color: #00755e;
      }
    }
  }
}

@media (max-width: 1024px) {
  header {
    display: none;
  }
}

// Component Header //
.component__header {
  display: flex;
  padding: 15px 25px;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  background-color: $main_color;
  text-decoration: none !important;
  .lang_btn {
    margin-top: 3px;
    margin-left: 0px !important;
    margin-right: 20px !important;
  }
  .header__auth-messages {
    .message_toogler {
      position: relative;
      outline: none;
      border: none;
      background: none;
      color: #c3f8e6;
      text-decoration: none;
      transition: all 0.2s linear;
      img {
        width: 32px;
      }
      &:hover {
        opacity: 0.5;
      }
      .message-count {
        position: absolute;
        top: -13px;
        left: 0px;
        display: block;
        min-width: 20px;
        height: 20px;
        border-radius: 20px;
        background-color: $danger_color;
        border: 2px solid $main_color;
        color: #c3f8e6;
        font-size: 0.7em;
        line-height: 17px;
        text-align: center;
      }
    }
  }

  .auth-operator-toogler {
    display: flex;
    color: #fff !important;
    img {
      width: 40px;
      margin-top: -4px;
    }
    span {
      line-height: 20px;
      margin-left: 10px;
      font-weight: bold;
    }
    .operator-name {
      font-weight: normal;
    }
  }
  .log-out-side {
    margin-top: 3px;
    margin-left: 13px;
    i {
      color: #fff;
      opacity: 0.8;
      font-size: 1.8rem;
      transition: all 0.2s linear;
      &:hover {
        opacity: 0.5;
        cursor: pointer;
      }
    }
  }
  .header__menu-toogler {
    img {
      width: 32px;
      margin-left: 10px;
      transition: 0.3s ease;
      &:hover {
        opacity: 0.5;
      }
    }
  }
  i {
    font-size: 2.3em;
    cursor: pointer;
    color: #fff;
  }
  .component__header-title {
    text-decoration: none !important;
    h1 {
      color: #fff;
      font-size: 1.2em;
    }
  }
  .component__header-left {
    display: flex;
    img {
      margin-right: 4.8rem;
    }
    p {
      position: absolute;
      font-size: 25px;
      margin: 0 !important;
      margin-left: 1.7em !important;
      font-family: "MS Gothic";
    }
  }
  .delete_side {
    width: 20rem;
    display: flex;
  }
  .component__header-link {
    width: 30%;
    a {
      text-decoration: none !important;
    }
    span {
      padding: 10px 30px;
      width: 100% !important;
    }
  }
  .component__header-delete-all {
    width: 100%;
    display: flex;
    color: #fff;
    cursor: pointer;
    white-space: nowrap;
    p {
      margin: 4px 0 0 4px !important;
    }
  }
  .component__header-delete {
    display: flex;
    color: #fff;
    cursor: pointer;
    white-space: nowrap;
    i {
      font-size: 25px;
      margin: 3px 0 0 1.5em !important;
      transition: all 0.3s;
    }
    p {
      margin: 5px 0 0 4px !important;
    }
  }
  .component__header-search {
    width: 40%;
    input {
      height: 2.8em;
      &::placeholder {
        color: #c1eedf;
        font-size: 15px;
      }
    }
  }
  .slider-section__header {
    width: 100%;
    padding: 100px 20px;
    display: flex;
    img {
      position: absolute;
      left: 50% !important;
      top: 0;
      transform: translate(-50%, 50%);
    }
    .header__auth {
      display: flex;
      align-items: center;
      flex-direction: column;
      position: absolute;
      left: 50% !important;
      top: 0;
      transform: translate(-52%, 110%);
      .contact_number {
        margin-right: -2rem;
        margin-bottom: 1.5rem;
        span {
          color: #fff;
          font-size: 20px;
          font-weight: 700;
        }
        img {
          margin-top: -6px;
          margin-left: -1.6rem !important;
          margin-top: -10px;
        }
      }
      .signin_btn {
        width: 170px;
        height: 2.3rem;
        background-color: transparent;
        border: 2px solid #fff;
        border-radius: 6px;
        transition: 0.3s ease;
        color: #fff;
        text-decoration: none;
        font-size: 15px;
        outline: none;
        &:hover {
          background-color: #fff;
          color: #2dce89;
        }
      }
      .signup_btn {
        width: 170px;
        height: 2.3rem;
        background-color: transparent;
        border-radius: 6px;
        border: 2px solid $light_green;
        background-color: $light_green;
        font-size: 15px;
        transition: 0.3s ease;
        color: #2dce89;
        outline: none;
        text-decoration: none;
        &:hover {
          background-color: transparent;
          color: #fff;
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .component__header {
    display: flex;
    margin: 0;
    padding: 25px 20px 15px 20px !important;
    flex-direction: column;
    background: linear-gradient(to right, $secondary_color, $main_color);
    .component__header-title {
      h1 {
        margin-top: 5px !important;
        margin-bottom: 1rem;
        color: #fff;
        font-size: 1em;
      }
    }
    .dd_exam {
      display: none;
    }
    .delete_side {
      width: 15rem;
      display: flex;
    }
    .component__header-search {
      width: 100%;
      input {
        height: 2.8em;
      }
    }
    .component__header-link {
      width: 100% !important;
      margin: 10px 0.9rem 0 0;
      height: 2.8rem;
    }
    .message_toogler {
      margin-right: 10px;
      img {
        opacity: 0.8;
        margin-top: -8px;
        width: 40px !important;
        height: 50px !important;
      }
      .message-count {
        left: 20px !important;
        margin-left: 0.6rem;
      }
    }
  }
}
