@import "variable";

.Number {
  .number__content {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 100px 50px;
    form {
      width: 40%;
    }
    .number-verification {
      width: 100% !important;
      .styles_react-code-input__CRulA {
        margin: 0 auto;
        display: flex;
        justify-content: center;
        flex-direction: row;
        input {
          width: 14.4444% !important;
          margin: 0 5px !important;
          height: 60px !important;
          background-color: transparent;
          border: 2px solid $main_color !important;
          border-radius: 10px !important;
          color: #808080 !important;
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .Number {
    .number__content {
      padding: 50px 20px;
      form {
        width: 90%;
      }
    }
  }
}
