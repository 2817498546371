@import "variable";

.CarAdd,
.CarEdit {
  .car-add__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 50px 10px;
    form {
      width: 60%;
      .car__number-type {
        display: flex;
        align-items: center;
        justify-content: space-around;
        .number-item {
          cursor: pointer;
          position: relative;
          border: 2px solid $main_solid;
          border-radius: 20px;
          width: 45%;
          padding: 9px 0;
          display: flex;
          background-color: #fff;
          align-items: center;
          justify-content: center;
          span {
            font-size: 0.9em;
            font-weight: 500;
          }
          img {
            margin-right: 5px;
          }
        }
        .number-item.selected {
          background-color: $main_color;
          color: #fff;
          border: none;
          border: 2px solid $main_color;
        }
      }
    }
    .car-add__info {
      width: 80%;
    }
  }
}

@media (max-width: 1024px) {
  .CarAdd,
  .CarEdit {
    .car-add__content {
      padding: 50px 20px;
      form {
        width: 100%;
        .car__number-type {
          .number-item {
            span {
              font-size: 0.7em;
            }
          }
        }
      }
      .car-add__info {
        width: 100%;
      }
      .car-add__image {
        width: 100%;
        img {
          width: 70%;
        }
      }
    }
  }
}
