@import "variable";

.Dashboard {
  padding: 48px;
  min-height: 600px !important ;
  display: flex;
  border-radius: 12px;
  background-color: #fff;
  flex-wrap: wrap;
  .dash__link-item {
    border: none;
    outline: none;
    text-decoration: none;
    background: none;
    display: block;
    padding: 20px 30px;
    width: 100%;
    height: 150px;
    border-radius: 25px;
    box-shadow: 0px 0px 20px #525f7f1f;
    -webkit-box-shadow: 0px 0px 20px #525f7f1f;
    transition: all 0.2s linear;
    .dash__link-icon {
      position: relative;
      width: 70px;
      height: 70px;
      border-radius: 20px;
      margin: 0px auto 10px auto;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $main_default;
      svg {
        width: 44px;
        height: 44px;
      }
      img {
        width: 28px;
        height: 28px;
        object-fit: contain;
      }
      > .dash__link-badge {
        position: absolute;
        top: -8px;
        right: -12px;
        border: 2px solid #fff;
        border-radius: 500rem;
        font-size: 11px;
        font-weight: 400;
        color: #fff;
        background-color: #17C653;
      }
    }
    .dash__link-text {
      text-align: center;
      p {
        font-size: 0.8em;
        color: black;
        margin: 0px;
      }
    }
    .dash__link-balance {
      text-align: center;
      p {
        color: $success_color;
        font-size: 0.7em;
        margin: 0px;
      }
    }
    &:hover {
      box-shadow: 0px 0px 20px 10px #00000012;
    }
  }
}

@media (min-width: 1024px) and (max-width: 1440px) {
  .Dashboard {
    .dash__link-item {
      padding: 25px 10px;
      //height: 130px;
      .dash__link-icon {
        width: 80px;
        height: 80px;
        margin: 0px auto 5px auto;
      }
      .dash__link-text {
        p {
          font-size: 0.7em;
        }
      }
      .dash__link-balance {
        p {
          font-size: 0.6em;
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .Dashboard {
    padding: 20px 0px;
    .dash__link-item {
      padding: 5px;
      height: 100px;
      border-radius: 0px;
      box-shadow: none;
      .dash__link-icon {
        width: 50px;
        height: 50px;
        border-radius: 15px;
        margin: 0px auto 5px auto;
        svg {
          width: 30px;
          height: 30px;
        }
        i {
          font-size: 1.5em;
        }
        img {
          width: 20px;
          height: 20px;
          object-fit: contain;
        }
      }
      .dash__link-text {
        p {
          font-size: 0.8em;
        }
      }
      &:hover {
        box-shadow: none;
      }
    }
  }
}
