$dark: #1e232c;
$main_color: #089b7e;
$secondary_color: #16a085;
$secondary_light: rgba(224, 238, 235, 1);
$success_color: #48d296;
$main_op: #35b69d;
$danger_color: #ff5d5d;
$warning_color: #ff9500;
$main_solid: #6ec8aa;
$main_solid_gray: #f8f8fe;
$main_solid_dark: #cccccc;
$main_default: #ffff;
$main_gray: #7a8091;
$main_dark: #22233a;
$main_blue: #283f94;
$light_green: #c3f8e6;
$color_green: #c7ecdf;
$white: #fff;

.text-dark {
  color: $dark;
}

.fs-14 {
  font-size: 14px;
}
.is-14 {
  width: 14px;
  height: 14px;
}

.is-18 {
  width: 18px;
  height: 18px;
}

.cursor-pointer {
  cursor: pointer;
}
.fs-20 {
  font-size: 20px;
}
.is-20 {
  width: 20px;
  height: 20px;
}

.fs-22 {
  font-size: 22px;
}
.is-22 {
  width: 22px;
  height: 22px;
}

.fs-24 {
  font-size: 24px;
}
.is-24 {
  width: 24px;
  height: 24px;
}

.fs-26 {
  font-size: 26px;
}
.is-26 {
  width: 26px;
  height: 26px;
}

.fs-28 {
  font-size: 28px;
}
.is-28 {
  width: 28px;
  height: 28px;
}

.fs-30 {
  font-size: 30px;
}
.is-30 {
  width: 30px;
  height: 30px;
}

.is-44 {
  width: 44px;
  height: 44px;
}
