.slider__side {
  width: 100%;
  h4 {
    text-align: center;
    padding: 2.5rem 0 1rem 0;
    color: #fff;
  }
  .slider_ {
    width: 90%;
    min-height: 500px;
    margin: 0 auto;
    .slick-next:before, [dir=rtl] .slick-prev:before {
      font-family: feather !important;
      content: '\e92a' !important;
      font-size: 50px !important;
      margin: -.4em 0 0 -2px !important;
      position: absolute;
    }
    .slick-prev:before {
      color: #fff !important;
      font-family: feather !important;
      content: '\e929' !important;
      font-size: 50px !important;
      margin: -.4em 0 0 -3rem !important;
      position: absolute;
    }

    .slick-dots li button:before {
      font-size: 17px !important;
      color: #fff;
      opacity: 1 !important;
      line-height: 40px !important;
    }
    .slick-dots li.slick-active button:before {
      color: $main_color;
    }

    img {
      border-radius: 10px;
    }
  }
}


@media (max-width: 1024px) {
  .slider__side {
    margin-top: 0;
    .slider_ {
      width: 90%;
      margin: 0 auto;
        .slick-next:before, [dir=rtl] .slick-prev:before {
          margin: -.4em 0 0 -1.5rem !important;
        }
        .slick-prev:before {
          margin: -.4em 0 0 -1.7rem !important;
        }
        .slick-dots li button:before {
          font-size: 14px !important;
          line-height: 40px !important;
        }
        img {
          border-radius: 0;
          padding: 0 15px;
        }
      }
    }
}

@media (max-width: 824px) {
  .slider__side {
    .slider_ {
      margin-top: 0rem ;
      padding-bottom: 3rem!important;
      }
    }
}


@media (max-width: 818px) {
  .slider__side {
    padding-bottom: 3rem !important;
    .slider_ {
      width: 80%;
      margin: 0 auto;
      margin-top: 0 !important;
      .slick-next:before, [dir=rtl] .slick-prev:before {
        margin: -.4em 0 0 -.9rem !important;
        font-size: 40px !important;
      }
      .slick-prev:before {
        margin: -.4em 0 0 -1.7rem !important;
        font-size: 40px !important;
      }
      .slick-dots li button:before {
        font-size: 14px !important;
        line-height: 40px !important;
      }
      img {
        border-radius: 0;
        padding: 0 5px;
      }
    }
  }
}



@media (max-width: 410px) {
  .slider__side {
    .slider_ {
      width: 93%;
      overflow: hidden;
      margin: 0 auto;
      margin-top: 0 !important;
      .slick-next:before, [dir=rtl] .slick-prev:before {
        display: none ;
      }
      .slick-prev:before {
        display: none ;
      }
      .slick-dots li button:before {
        font-size: 14px !important;
        line-height: 40px !important;
      }
      img {
        border-radius: 0;
        padding: 0 5px;
      }
    }
  }
}