.langs {
  display: flex;
  flex-direction: column;
  .lang-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 14px 1rem;
    cursor: pointer;
    border-radius: 1rem;
    transition: all 0.3s;
    margin-bottom: 0.5rem;
    .lang-detail {
      margin-left: 12px;
      .title {
        color: $secondary_color;
        font-weight: 600;
        font-size: 16px;
      }
      .caption {
        font-size: 14px;
        color: $dark;
        font-weight: 600;
      }
    }
    &:hover {
      background-color: $secondary_light;
    }
    &.active {
      background-color: $secondary_light;
    }
  }
}
