@import "variable";

.Balance {
  .balance__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 50px 20px;

    .form-content {
      width: 37.8rem;
      display: flex;
      justify-content: center;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
      align-items: center;
      background-color: $main_default;
      padding: 20px 0;
      margin-top: 1.5em;
      border-radius: 7px;

      form {
        width: 70%;
      }
    }

    .balance__info {
      width: 80%;
    }

    .balance__image {
      width: 40%;
      display: flex;
      justify-content: center;

      img {
        width: 60%;
        object-fit: contain;
      }
    }

    .balance__question {
      text-decoration: underline;
      color: $main_color;
    }

    .warn_balance {
      width: 37.8rem;
    }
  }

  .balance-card {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
    background-color: $main_default;
    padding: 15px 20px;
    border-radius: 7px;

    p {
      font-size: 16px;
      transition: all .2s;
      color: #AEAEAE;
    }

    &:hover {
      text-decoration: none !important;

      p {
        color: $main_blue;
      }
    }
  }
}

.balance-modal {
  width: 100%;
  padding: 0 10px;

  .balance-modal--item {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 2.5rem;
    a {
      text-align: center;
      img {
        width: 70%;
        border-radius: 10px;
      }
    }
  }
}


@media (max-width: 1024px) {
  .Balance {
    .balance__content {
      padding: 50px 20px;

      .form-content {
        width: 100%;

        form {
          width: 90%;
        }
      }

      .balance__info {
        width: 100%;
      }

      .balance__image {
        width: 60%;

        img {
          width: 200px;
        }
      }

      .warn_balance {
        width: 100%;
      }
    }
  }
  .balance-card {
    margin-top: 1rem;
  }
}
