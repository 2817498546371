@import "variable";

.Password {
  .password__content {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px;
    form {
      width: 40%;
    }
  }
}

@media (max-width: 1024px) {
  .Password {
    .password__content {
      padding: 50px 20px;
      form {
        width: 90%;
      }
    }
  }
}
