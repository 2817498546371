.exam__content-noInfo {
  width: 100%;
  margin-right: 1em;
  background-color: #fff;
  padding:  10px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.exam__content {
  width: 100%;
  min-height: 500px;
  padding: 1.5rem;
  display: flex;
  .exam-right_side {
    width: 100%;
    background-color: #fff;
    border-radius: 10px;
    display: flex;
    padding: 30px 20px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    img {
      width: 80%;
    }
  }
  .question-answers {
    width: 80% !important;
  }
}
.question-image {
  width: 100%;
}


.answer-btn-group {
  button {
    outline: 0;
    border: 0;
    background: #aeaeae;
    color: #fff;
    border-radius: 7px;
    padding: 5px 25px;
    transition: background 0.2s ease, color 0.2s ease;

    &.active,
    &:hover {
      background-color: $main_color;
      color: #fff;
    }

    // &:not(.active) {
    //   &:active,
    //   &:focus {
    //     background: #e1e1f8;
    //     color: #5956d5;
    //   }
    // }
  }
}

@media (max-width: 1024px) {
  .exam__content-noInfo {
    padding:  20px;
    img {
      width: 70%;
      object-fit: contain;
    }
  }
  .exam__content {
    padding: .4rem !important;
    display: flex;
    flex-direction: column;
    .exam-right_side {
      width: 100% ;
      background-color: #fff;
      border-radius: 10px;
      img {
        width: 90%;
      }
    .question-answers {
      width: 90% !important;
    }
    }
  }
}

@media (max-width: 600px) {
  .exam__content {
    .exam-right_side {
      img {
        width: 100% !important;
      }
      .question-answers {
        width: 100% !important;
      }
    }
  }
}

@media (min-width: 992px) {
  .question-image {
    width: 70%;
  }
}