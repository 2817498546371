@import "variable";

.LicenseAdd {
  .license-add__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 50px 20px;
    form {
      width: 40%;
    }
  }
}

@media (max-width: 1024px) {
  .LicenseAdd {
    .license-add__content {
      padding: 50px 20px;
      form {
        width: 90%;
      }
    }
  }
}
