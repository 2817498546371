@import "variable";
@import "app";
@import "header";
@import "footer";
@import "loader";
@import "login";
@import "register";
@import "forgot";
@import "dashboard";
@import "messages";
@import "password";
@import "balance";
@import "number";
@import "my-cards";
@import "register";
@import "cars";
@import "caradd";
@import "law";
@import "faq";
@import "protocol";
@import "protocolpay";
@import "license";
@import "licenseadd";
@import "point";
@import "insurance";
@import "exam";
@import "alert";
@import "slider";
@import "content";
@import "warn";
@import "buttons";
@import "popup";
@import "langs";
@import "profile";
@import "navimax";

//Defaults
body {
  font-family: "Inter", sans-serif;
}

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-weight: 400;
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: $main_color;
    border-radius: 20px;
  }
}

.leaflet-container {
  width: 100%;
  height: 400px;
  border-radius: 0 0 1rem 1rem;
}

.print-body {
  background-color: transparent !important;

  &:after {
    background: transparent !important;
  }
}

canvas {
  display: none;
}

.technical_list {
  display: flex;
  flex-direction: column;
  padding: 1rem 0;
  .item {
    display: flex;
    flex-direction: column;
    padding: 0 1rem;
    .date {
      color: #8391a1;
      font-size: 14px;
      margin-left: 8px;
    }
    .description {
      color: #3d3d3d;
      font-size: 14px;
      margin-top: 8px;
    }
    svg {
      width: 20px;
      height: 20px;
    }
  }
}
.technical_list-divider {
  width: 100%;
  height: 2px;
  background-color: #e9e9e9;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.modal-backdrop {
  &.active {
    z-index: 1050 !important;
  }
}

.lh-18 {
  line-height: 18px;
}

.ml-12 {
  margin-left: 12px;
}

.protocol_warn {
  .warn {
    justify-content: flex-start;
  }
}

.accident {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 50%;
  margin: 4rem auto 0 auto;
  .balance {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
    a {
      border-radius: 30px;
    }
    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 44px;
      height: 44px;
      background-color: $secondary_color;
      border-radius: 12px;
    }
    .coin {
      font-weight: 600;
      font-size: 16px;
    }
    span {
      font-size: 14px;
      font-weight: 400;
      color: #8391a1;
    }
  }
  form {
    width: 100%;
  }
}

.custom-row__data {
  width: 100%;
  display: flex;
  .data-title,
  .data-content {
    min-width: 50%;
    margin: 5px 0px;
    padding: 0px 5px;
    font-weight: 500;
    font-size: 0.9em !important;
    display: flex;
    align-items: center;
  }

  .data-content {
    color: $dark;

    .link {
      padding: 0px 10px;
      font-size: 0.9em;
    }
  }

  .data-title {
    color: #8391a1;
  }
}

.protocol-buttons {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: row;
}

.dropdown-menu {
  top: 5px !important;
  left: -31px !important;

  .dropdown-item {
    display: flex;
    align-items: center;
    padding: 0.25rem 20px !important;

    i {
      margin-right: 5px;
    }

    p {
      margin: 0;
    }
  }
}

::selection {
  color: $main_default;
  background: $main_color;
}

body {
  background-color: $main_solid;

  &:after {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 110px;
    background: linear-gradient(to right, $secondary_color, $main_color);
  }
}

@media (max-width: 1024px) {
  body {
    &:after {
      display: none !important;
    }
  }
  .accident {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 90%;
  }
}

.backdrop {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 8;
  background-color: $main_color;
  opacity: 0.5;
}

.swal2-actions {
  display: flex !important;
  width: 100% !important;
  flex-direction: row !important;
  justify-content: space-around !important;
  padding: 0 10px !important;

  button {
    background-color: #48d296;
    width: 47%;
    color: #fff;
    height: 3rem !important;
    border-radius: 500rem;

    &:hover {
      color: #fff;
      border: 2px solid #09ac69;
      background-color: #09ac69;
    }
  }

  .swal2-cancel {
    color: #48d296;
    background-color: transparent;
    border: 2px solid #48d296;

    &:hover {
      background-color: #09ac69;
      border: 2px solid #09ac69;
      color: #fff;
    }
  }
}

.swal2-popup {
  border-radius: 10px !important;
}

@media (max-width: 1024px) {
  .swal2-top {
    width: 100% !important;
  }

  .swal2-actions {
    width: 100% !important;
  }
}

.custom-link {
  text-decoration: none !important;
  color: #fff;

  &:hover {
    color: $light_green;
  }
}

.core-rotating-icon {
  .feather {
    -webkit-animation: spin 1s linear infinite;
    -moz-animation: spin 1s linear infinite;
    animation: spin 1s linear infinite;
    display: inline-block;
  }
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.custom-btn {
  outline: none !important;
  color: $main_default;
  background-color: $main_color;
  display: inline-block;
  text-align: center;
  padding: 9px 15px;
  border: 2px solid $main_color;
  border-radius: 5px;
  text-decoration: none !important;
  transition: all 0.2s;

  &:hover {
    color: #fff;
    background-color: #05886d;
    border: 2px solid #05886d;
    transform: translateY(-1px);
  }

  &:active {
    color: #fff;
    background-color: $main_color;
    border: 2px solid $main_color;
    transform: translateY(0);
  }

  i {
    margin-right: 5px;
  }
}

.custom-btn_view {
  outline: none !important;
  background-color: transparent;
  border: 1px solid #2dce89;
  font-weight: 600;
  height: 2.8rem;
  margin-top: 7px;
  color: $main_color;
  transition: all 0.4s ease;

  &:hover {
    background-color: $main_color;
    color: #fff;
  }
}

.custom-btn_pay {
  outline: none !important;
  background-color: $main_color;
  border: 1px solid $main_color;
  font-weight: 600;
  height: 2.8rem;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.4rem;
  transition: all 0.4s ease;
  text-decoration: none !important;

  .print_dark {
    display: none;
  }

  &:hover {
    background-color: transparent;
    color: $main_color;

    .print_dark {
      display: block;
    }

    .print_white {
      display: none;
    }
  }
}

@media (max-width: 580px) {
  .custom-btn_pay {
    outline: none !important;
    background-color: $main_color;
    border: 1px solid $main_color;
    font-weight: 600;
    color: #fff;
    margin-top: 1rem;
    padding: 9px 70px;
    transition: all 0.4s ease;
    text-decoration: none !important;
    font-size: 14px !important;

    &:hover {
      background-color: transparent;
      color: $main_color;
    }
  }
  .custom-btn_view {
    margin-top: 1rem;
  }
}

.custom-btn.min {
  outline: none !important;
  padding: 3px 10px;
}

.light-btn {
  outline: none !important;
  color: $light_green;
  padding: 10px 0;
  border: 2px solid $light_green;
  background-color: transparent;
  font-size: 17px;

  &:hover {
    background-color: $light_green;
    color: $secondary_color;
  }
}

.default-btn {
  outline: none !important;
  color: $main_gray;
  background-color: $main_solid;
  border: none;

  &:hover {
    background-color: $main_solid_gray;
    color: $main_gray;
  }
}

.success-btn {
  color: $main_default;
  border: 2px solid $success_color;
  background-color: $success_color;

  &:hover {
    color: $main_default;
    border-color: darken($color: $success_color, $amount: 20);
    background-color: darken($color: $success_color, $amount: 20);
  }
}

.round {
  position: relative;
  padding: 17px;
  margin-top: 2.6em;
}

.round label {
  background-color: transparent;
  border: 2px solid $main_color;
  border-radius: 50%;
  cursor: pointer;
  height: 28px;
  left: 0;
  position: absolute;
  top: 0;
  width: 28px;
}

.round label:after {
  background-color: $main_color;
  content: "";
  border-radius: 500rem !important;
  border: 2px solid $main_color;
  border-top: none;
  border-right: none;
  content: "";
  top: 3px;
  left: 3px;
  opacity: 0;
  position: absolute;
  width: 12px;
  padding: 8px;
}

.round input[type="checkbox"] {
  visibility: hidden;
}

.round input[type="checkbox"]:checked + label:after {
  opacity: 1;
}

.danger-btn {
  color: $main_default;
  border: 2px solid $danger_color;
  background-color: $danger_color;

  &:hover {
    color: $main_default;
    border-color: darken($color: $danger_color, $amount: 20);
    background-color: darken($color: $danger_color, $amount: 20);
  }
  &:active {
    color: $main_default;
    border-color: darken($color: $danger_color, $amount: 20);
    background-color: darken($color: $danger_color, $amount: 20);
  }
}

.warning-btn {
  color: $main_default;
  border: 2px solid #ffb800;
  background-color: #ffb800;

  &:hover {
    color: $main_default;
    border-color: #ffb800;
    background-color: #ffb800;
  }
  &:active {
    color: $main_default;
    border-color: #ffb800;
    background-color: #ffb800;
  }
}

.info-btn {
  position: relative;
  outline: none !important;
  border: none;
  border-radius: 100%;
  width: 25px;
  height: 25px;
  background-color: $main_solid;

  .info__image {
    position: absolute;
    bottom: 0px;
    left: 40px;
    z-index: 10;
    padding: 5px;
    border-radius: 10px;
    background-color: $main_default;
    box-shadow: 0px 0px 20px #00000012;

    &::after {
      content: "";
      position: absolute;
      bottom: 5px;
      left: -19px;
      width: 20px;
      height: 20px;
      background-color: $main_default;
      clip-path: polygon(100% 0, 30% 50%, 100% 100%);
      box-shadow: 0px 0px 20px #00000012;
    }
  }
}

@media (max-width: 1024px) {
  .in_bottom {
    display: none;
  }
  .info-btn {
    .info__image {
      bottom: 0px;
      left: -100%;

      img {
        width: 150px;
        object-fit: contain;
      }

      &::after {
        display: none;
      }
    }
  }
  .custom-link {
    font-size: 14px;
  }
}

.link-btn {
  color: $main_color;
  border: none;
  outline: none;
  background: none;
  display: flex;
  align-items: center;

  span {
    font-size: 0.8em;
  }

  img {
    margin-right: 10px;
  }

  &:hover {
    color: $secondary_color;
  }
}

@media (max-width: 1024px) {
  .link-btn {
    img {
      margin-right: 5px;
    }

    span {
      font-size: 0.6em;
    }
  }
}

.br {
  border-radius: 25px !important;
}

// Spinner
.rotation-icon {
  animation: spin 2s infinite linear;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.custom-container {
  position: relative;
  width: 75%;
  margin: auto;
}

@media (max-width: 1024px) {
  .custom-container {
    width: 100%;
  }
}

.custom-row {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 10px 0px;
}

.custom-row:not(:last-child) {
  border-bottom: 1px solid $main_solid_dark;
}

.custom-alert {
  padding: 20px 15px;
  display: flex;
  align-items: center;

  i {
    padding-right: 20px;
    font-size: 1.5em;
  }
}

@media (max-width: 1024px) {
  .custom-alert {
    font-size: 0.7em;
  }

  .verification_input {
    width: 100% !important;

    .styles_react-code-input__CRulA {
      margin: 0 auto;
      display: flex;
      justify-content: center;
      flex-direction: row;

      input {
        width: 16% !important;
        margin: 0 5px !important;
        height: 80px !important;
        background-color: #35b69d;
        border-radius: 10px !important;
        color: #fff !important;
      }
    }
  }
}

@media (max-width: 555px) {
  .verification_input {
    .styles_react-code-input__CRulA {
      input {
        height: 50px !important;
      }
    }
  }
}

.custom-alert.success {
  color: #00844b;
}

.custom-alert.warning {
  color: $warning_color;
}

.custom-label {
  color: #fff;
  margin-bottom: 10px;
  margin-right: 10px;
}

.custom-different-label {
  color: #808080;
  margin-bottom: 3px;
  margin-right: 30px;
  font-size: 14px;
}

.custom-label-error {
  color: $danger_color;
  margin-bottom: 10px;
  margin-right: 10px;
}

.get_insurance {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;

  .i_item {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    padding: 5px 10px;
    border-radius: 6px;
    border: 1px solid #f4f4f4;

    a {
      color: #000;
      margin-left: 10px;
    }
  }
}

.custom-textarea_simple {
  width: 100%;
  padding: 15px 20px;
  border: none;
  outline: none;
  resize: none;
  background: #fff;
  border-radius: 8px;
  color: $main_color;

  &::placeholder {
    color: $main_color;
  }
}

.custom-input,
.custom-textarea,
.custom-select {
  width: 100%;
  padding: 15px 20px;
  border: none;
  outline: none;
  resize: none;
  background: #35b69d;
  border-radius: 8px;
  color: #fff;
  box-shadow: none !important;

  &::placeholder {
    color: #fff;
  }
}

.color-g {
  &:before {
    color: #fff !important;
  }
}

.select-container {
  position: relative;
  display: flex;
  align-items: center;

  &:before {
    font-family: "feather";
    content: "\e928";
    position: absolute;
    z-index: 4;
    font-size: 1.1rem;
    line-height: 6px;
    right: 12px;
    color: #808080;
  }

  .custom-different-select {
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 100%;
    padding: 7px 15px;
    outline: none;
    resize: none;
    background: #f6f6f6;
    color: #808080;
    border: 2px solid #e8e8e8;
    border-radius: 8px;
    position: relative;

    &::placeholder {
      color: $main_color;
    }

    &:focus {
      border: 2px solid #82f1cc;
    }

    &:required:invalid {
      color: rgba(128, 128, 128, 0.43);
    }

    option {
      color: #808080;
    }
  }
}

.custom-different-input {
  width: 100%;
  padding: 7px 15px;
  outline: none;
  resize: none;
  border-radius: 8px;
  background: #f6f6f6;
  color: #808080;
  border: 2px solid #e8e8e8;
  position: relative;

  &::placeholder {
    color: rgba(128, 128, 128, 0.43);
  }

  &:focus {
    border: 2px solid #82f1cc;
  }

  &:disabled {
    opacity: 0.8;
  }
}

.custom-input.min,
.custom-textarea.min,
.custom-select.min {
  padding: 10px 20px;
}

.custom-select {
  min-height: 53px;
}

.slash_ {
  position: absolute;
  top: 2.7rem;
  left: -4px;
  font-size: 22px;
  color: #09ac69;
}

.my_radio {
  input[type="radio"] {
    -webkit-appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid darkgray;
    border-radius: 50%;
    outline: none;
  }

  input[type="radio"]:hover {
    box-shadow: 0 0 5px 0 orange inset;
  }

  input[type="radio"]:before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }

  input[type="radio"]:checked:before {
    background: $secondary_color;
  }

  input[type="radio"]:before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
}

.custom-outline_btn {
  outline: none !important;
  width: 100%;
  padding: 18px 40px;
  background-color: transparent;
  border: 2px solid #55c3ae;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s ease;
  color: $light_green;
  text-decoration: none !important;
  font-size: 15px;
  margin-top: 2px;
  outline: none;

  &:hover {
    background-color: #55c3ae;
    border: 2px solid #55c3ae;
    color: #fff;
  }
}

.custom-outline_btn-dark {
  outline: none !important;
  width: 100%;
  padding: 18px 40px;
  background-color: transparent;
  border: 2px solid $main_color;
  border-radius: 6px;
  transition: 0.3s ease;
  color: $main_color;
  text-decoration: none;
  font-size: 15px;
  margin-top: 2px;
  outline: none;

  &:hover {
    background-color: $main_color;
    border: 2px solid $main_color;
    color: #fff;
  }
}

.custom-checkbox {
  display: inline-block;
  position: relative;
  cursor: pointer;
  font-size: 15px;
  user-select: none;

  .title {
    margin: 0px;
    padding-left: 25px;
    color: #fff;
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked ~ .checkmark {
      background-color: $main_color;

      &:after {
        display: block;
      }
    }
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: transparent;
    border: 2px solid #fff;
    border-radius: 5px;

    &:after {
      content: "";
      position: absolute;
      display: none;
      left: 52%;
      top: 45%;
      width: 5px;
      height: 10px;
      border: solid white;
      border-width: 0 3px 3px 0;
      transform: translate(-50%, -50%) rotate(45deg);
    }
  }
}

@media (min-width: 1024px) {
  .custom-col-md-20 {
    width: 25%;
    padding: 10px;
  }

  .custom-col-md-25 {
    width: 25%;
    padding: 10px;
  }
}

@media (max-width: 1024px) {
  .custom-col-4 {
    width: calc((4 / 12) * 100%);
    padding: 5px;
  }

  .export__btn_one {
    display: flex;
    flex-direction: column;

    .custom-btn_pay {
      margin: 1rem 0 0 0 !important;
    }

    .custom-btn {
      width: 100% !important;
    }
  }

  .custom-col-6 {
    width: calc((6 / 12) * 100%);
    padding: 5px;
  }

  .custom-checkbox {
    font-size: 14px;
    margin: -1px -2px 0 0;
  }
  .checkmark {
    position: absolute;
    top: 1px !important;
    left: 0;
    height: 17px !important;
    width: 17px !important;
    background-color: transparent;
    border: 2px solid #fff;
    border-radius: 5px;
  }
}

.pay_content {
  width: 55%;
  margin: 0 auto;

  .card-content {
    min-height: 320px;
  }

  form {
    margin: 0 auto;
    padding: 1rem 1.5rem;
  }
}

@media (max-width: 1024px) {
  .pay_content {
    width: 100%;
    margin: 0 auto;

    .card-content {
      min-height: 350px;
    }

    form {
      width: 60%;
      margin: 0 auto;
    }
  }
}

//@media print {
//  .print_content {
//    padding: 2rem 6rem;
//  }
//}

@media (max-width: 600px) {
  .pay_content {
    width: 100%;
    margin: 0 auto;

    .card-content {
      min-height: 350px;
    }

    form {
      width: 100%;
      margin: 0 auto;
    }
  }
}

.custom__modal-container {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 99;
  width: 100%;
  height: 100vh;
  background-color: #00000080;

  .custom__modal {
    position: absolute;
    width: 500px;
    min-height: 150px;
    border-radius: 10px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    background-color: $main_default;
    box-shadow: 0px 0px 20px #00000012;
    animation: fadeIn 0.2s linear 1 forwards;

    .custom__modal-header {
      position: relative;
      max-height: 20%;
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.07);
      display: flex;
      align-items: center;
      padding: 15px 20px;

      .close-btn {
        outline: none !important;
        position: absolute;
        right: 15px;
        border: none;
        outline: none;
        background: none;
        font-size: 1.5em;
        transition: all 0.2s linear;

        &:hover {
          transform: rotate(90deg);
        }
      }
    }

    .custom__modal-body {
      max-height: 80%;
      padding: 30px;
      display: flex;
      flex-direction: column;
      align-items: center;

      form {
        width: 50%;
      }

      .modal_social-icons {
        display: flex;
        width: 100%;
        justify-content: space-around;

        .icon-item {
          img {
            width: 50px;
            height: 50px;
            object-fit: contain;
          }
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .custom__modal-container {
    .custom__modal {
      position: relative;
      width: 95%;
      min-height: 100px;

      .custom__modal-body {
        padding: 20px 10px;

        form {
          width: 90%;
        }

        .modal_social-icons {
          .icon-item {
            img {
              width: 30px;
              height: 30px;
            }
          }
        }
      }
    }
  }
}

@keyframes fadeIn {
  100% {
    transform: translate(-50%, -50%) scale(1);
  }
}

.pay_content {
  margin-top: 1rem;
}

@media (max-width: 1024px) {
  .pay_content {
    margin-top: 0rem;
  }
}
