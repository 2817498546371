@import "variable";

.Law {
  .law__container {
    padding: 50px;
    .law__item-first {
      background-color: #eee !important;
    }
    .law__item-first,
    .law__item {
      // cursor: pointer;
      display: block;
      text-decoration: none;
      color: black;
      // opacity: 0.6;
      padding: 10px;
      margin-bottom: 20px;
      border-radius: 10px;
      transition: all 0.2s linear;
      background-color: #fff;
      &:hover {
        background-color: #eee;
      }
      .law__item-header {
        position: relative;
        display: flex;
        padding: 5px 20px;
        .law__item-name {
          p {
            color: $main_color;
            font-size: 1.1em;
            font-weight: 700;
            margin: 0px;
          }
        }
      }
      .law__item-content {
        padding: 5px 20px;
        p {
          margin: 0px;
          color:#000;
          font-size: 0.9em;
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .Law {
    .law__container {
      padding: 50px 10px;
    }
  }
}











