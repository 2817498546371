@import "variable";
@import "sidebar";

.Protocols {
  .protocols__content {
    display: flex;
    .protocols__container {
      width: 100%;
      max-height: 570px;
      min-height: 570px;
      padding: 24px 16px 0 10px !important;
      .protocols__content-main {
        position: relative;
        width: 100%;
        background-color: #fff;
        min-height: 520px;
        max-height: 520px;
        border-radius: 10px;
        padding: 0 10px;
        overflow-y: auto;
        .protocol__empty-content {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 40%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          img {
            width: 50%;
            object-fit: contain;
            margin-bottom: 20px;
          }
          .protocol__empty-content-text {
            color: $main_dark;
            font-size: 0.9em;
            text-align: center;
          }
        }
      }
    }
  }
  .protocol-file__content {
    .item-container {
      display: flex;
      flex-wrap: wrap;
    }
    .container-header {
      width: 100%;
      color: $main_blue;
      margin: 0px;
    }
    .protocol-file-item {
      cursor: pointer;
      width: 100%;
      border-radius: 10px;
      overflow: hidden;
      img {
        width: 100%;
        object-fit: contain;
      }
    }
  }
  .custom__modal.bg_modal {
    width: 70%;
    .custom__modal-body {
      padding: 18px 1rem !important;
      max-height: 80vh !important;
      overflow-y: scroll;
      .container-header {
        color: $secondary_color;
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
  .custom__modal-container.asset_modal {
    // background: none;
    z-index: 9999;
    .custom__modal.image-modal {
      width: auto;
      max-height: 95vh;
      .custom__modal-body {
        padding: 10px;
        img,
        video {
          width: 100%;
          max-height: 80vh;
        }
      }
    }
  }
}

.protocol-nav {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-bottom: 1rem;
  background-color: #e0eeeb;
  border-radius: 8px;
  .nav-item {
    text-align: center;
    .nav-link {
      font-size: 14px;
      color: $dark;
      padding-left: 0;
      padding-right: 0;
    }
    &:first-child {
      width: 45%;
      .nav-link {
        border-radius: 8px 0 0 8px;
      }
    }
    &:last-child {
      width: 55%;
      .nav-link {
        border-radius: 0 8px 8px 0;
      }
    }
    .nav-link {
      &.active {
        color: $white;
        background-color: $main_color;
      }
    }
  }
}

.protocol-pay__serie-container {
  width: 100% !important;
  display: flex;
  flex-direction: column;
}

@media (max-width: 1024px) {
  .Protocols {
    .custom__modal.bg_modal {
      width: 95%;
    }
    .custom__modal-container.asset_modal {
      .custom__modal.image-modal {
        width: 95%;
        .custom__modal-body {
          padding: 5px;
          img,
          video {
            width: 100%;
            height: 200px;
          }
        }
      }
    }
  }
}
